@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Inter-Regular.woff2') format('woff2'),
    url('/static/fonts/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  src: url('/static/fonts/Inter-SemiBold.woff2') format('woff2'),
    url('/static/fonts/Inter-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Inter-Bold.woff2') format('woff2'),
    url('/static/fonts/Inter-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-style: normal;
  src: url('/static/fonts/Inter-Black.woff2') format('woff2'),
    url('/static/fonts/Inter-Black.woff') format('woff');
}

.dot-animation {
  .root {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .dot {
    border-radius: 50%;
    animation: tp-loader-animation-1 1.3s infinite ease-in;
    opacity: 0;
    -moz-transform: rotate(0.1deg); // Fixes small rendering bug in FF;

    width: 12px;
    height: 12px;
    margin-left: 5px;
    margin-right: 5px;

    background-color: #29b5e8;
  }

  .dot:nth-child(2) {
    animation-name: tp-loader-animation-2;
  }

  .dot:nth-child(3) {
    animation-name: tp-loader-animation-3;
  }
}

@keyframes tp-loader-animation-1 {
  0%,
  70%,
  100% {
    opacity: 0.2;
  }
  30% {
    opacity: 1;
  }
}

@keyframes tp-loader-animation-2 {
  0%,
  15%,
  85%,
  100% {
    opacity: 0.2;
  }
  45% {
    opacity: 1;
  }
}

@keyframes tp-loader-animation-3 {
  0%,
  30%,
  100% {
    opacity: 0.2;
  }
  60% {
    opacity: 1;
  }
}

body {
  margin: 0;
}

.trial-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;

  .trial-page-header {
    justify-content: space-evenly;

    .trial-header-left {
      width: 540px;
      flex-shrink: 0;
    }

    .trial-header-right {
      width: 420px;
      flex-shrink: 0;
    }

    img {
      height: 50px;
    }
  }

  .trial-responsive-flex-container {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    justify-content: space-evenly;
    background-image: url('/static/images/background-blue-arrow.png'), linear-gradient(125deg, #27AEDF, #1D81A5);
    background-repeat: no-repeat, no-repeat;
    background-position: bottom -200px right -100px, 100%;
    background-size: 40%, 100%;
    padding: 80px 48px 80px 48px;

    .trial-left-container {
      // will override min-width on narrow screens
      max-width: 540px;
      min-width: 540px;
      flex-shrink: 0;

      .trial-info-list {
        width: 80%;
      }
    }

    .trial-right-container {
      flex-shrink: 0;
      /* the following three properties are used to smooth the transition for opening the video */
      width: 96%;
      margin: 0 2%;
      transition: all 0.25s ease-in-out;

      .trial-card-marketing-text {
        text-align: center;
      }

      .trial-logo-box {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        transition: all 0.1s ease-in-out;
      }

      .trial-logo-box::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      .trial-logo-box:hover {
        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
      }
    }
  }
}


@media (max-width: 1090px) {
  .trial-page-container {
    .trial-page-header {
      .trial-header-left {
        display: flex;
        justify-content: center;
      }
      .trial-header-right {
        display: none;
      }

      img {
        height: 32px;
      }
    }

    .trial-responsive-flex-container {
      flex-direction: column;
      align-items: center;
      background-image: linear-gradient(125deg, #27AEDF, #1D81A5);
      background-repeat: no-repeat;
      background-position: 100%;
      background-size: 100%;
      padding: 24px;

      .trial-left-container {
        align-items: center;
        order: 2;
        min-width: 300px;
        flex-shrink: 1;
        margin: 80px 0 0 0;

        .trial-title {
          text-align: center;
        }

        .trial-info-list {
          width: 90%;

          .trial-info-list-certification-icons {
            width: 100%;
          }
        }

        .trial-faq-line {
          text-align: center;
        }
      }

      .trial-right-container {
        flex-shrink: 1;
        margin: 0;
      }
    }
  }
}
